<template>
  <div class="operation">
    <span
      class="item"
      @click="previewReport(viewReportStatus.includes(status))"
    >
      <span :class="{ title: true, active: viewReportStatus.includes(status) }"
        >预览</span
      ></span
    >
    <span
      class="item"
      @click="confirmReport(confirmReportStauts.includes(status))"
    >
      <span
        :class="{ title: true, active: confirmReportStauts.includes(status) }"
        >确认</span
      ></span
    >
    <span
      class="item"
      @click="handleDownloadReport(downloadReportStauts.includes(status))"
    >
      <span
        :class="{ title: true, active: downloadReportStauts.includes(status) }"
        >下载</span
      ></span
    >
    <span
      class="item"
      @click="handleDownloadReport(downloadReportStauts.includes(status))"
    >
      <span
        :class="{ title: true, active: downloadReportStauts.includes(status) }"
        >分析</span
      ></span
    >
    <!-- 确认ASG-LTC -->
    <el-dialog :visible.sync="openAsgLtc" width="50%" class="bsgLtc">
      <p style="display: flex; justify-content: center; font-size: 24px">
        报告确认单
      </p>
      <div
        style="
          display: flex;
          flex-direction: column;
          font-size: 18px;
          line-height: 42px;
          margin-top: 36px;
          padding: 0 22px;
        "
      >
        <p class="report_desc">{{ reportDesc }}</p>
        <table class="table mt30">
          <tr>
            <td class="td wd30 tc">合同编号</td>
            <td class="td wd70 tc">123123</td>
          </tr>
          <tr>
            <td class="td wd30 tc">合同名称</td>
            <td class="td wd70 tc">123123</td>
          </tr>
          <tr>
            <td class="td wd30 tc">项目编号</td>
            <td class="td wd70 tc">123123</td>
          </tr>
          <tr>
            <td class="td wd30 tc">样本数量</td>
            <td class="td wd70 tc">123123</td>
          </tr>
          <tr>
            <td class="td wd30 tc">金额</td>
            <td class="td wd70 tc">123123</td>
          </tr>
          <tr>
            <td class="td wd30 tc">检测完成日期</td>
            <td class="td wd70 tc">123123</td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openAsgLtc = false">取 消</el-button>
        <el-button type="primary" @click="submitAsgLtcConfirmReport"
          >确 认</el-button
        >
      </span>
    </el-dialog>
    <!-- 下载文件 -->
    <download-file v-model="openDownloadFile" :data="myData" />
  </div>
</template>

<script>
import {
  getConfirmReport,
  submitReportConfirmation,
  downloadReport,
  downloadBsgLtcConfirmPdf,
} from "@/api/order/index";
import { saveAs } from "file-saver";
import { order } from "@/utils/dict";
import downloadFile from "./downloadFile.vue";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    downloadFile,
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      viewReportStatus: [15, 27, 29], // 可以预览报告的状态
      confirmReportStauts: [15], //可以确认报告的状态
      downloadReportStauts: [27, 29], //可以下载报告的状态
      pdfUrl: "", //pdf地址
      isSelectedConfirmReport: false, //确认报告协议勾选
      confirmReportParams: {
        confirmDate: "",
        orgName: "",
        reportNo: "",
      }, //确认报告参数
      openAsgLtc: false, //ASG-LTC确认报告弹窗
      openDownloadFile: false, //打开下载文件弹窗
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
      ], //表格数据
    };
  },
  computed: {
    status() {
      return this.myData.status;
    },
    // 获取报告秒速
    reportDesc() {
      return `您的项目“2222042600008+中国科学技术大学_胶条鉴定 3 个
          1h技术服务合同”本次5个样本的磷酸化谱DIA产品线已完成，项目报告请慧阅，并请确认此次服务金额：`;
    },
  },
  methods: {
    // 下载报告
    handleDownloadReport(active) {
      // if (!active) return;
      this.openDownloadFile = true;
      // this.$messageBox
      //   .confirm("此操作将下载报告, 是否继续?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //   .then(() => {
      //     const params = {
      //       orderNo: Number(this.myData.vbillcode),
      //       operationType: 2, //操作类型；1：预览。2：下载。
      //     };
      //     downloadReport(params).then((res) => {
      //       const { data, fileName } = res;
      //       const blob = new Blob([data], {
      //         type: "application/pdf;chartset=UTF-8",
      //       });
      //       const fileName2 = fileName.split(".")[0];
      //       saveAs(blob, fileName2);
      //       this.$message({
      //         type: "success",
      //         message: "下载报告成功",
      //       });
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消复制",
      //     });
      //   });
    },
    // 确认报告弹窗
    async confirmReport(active) {
      if (!active) return;
      this.openAsgLtc = true;
    },
    // 预览报告
    previewReport(active) {
      if (!active) return;
      const params = {
        orderNo: Number(this.myData.vbillcode), //订单编号
        operationType: 1, //操作类型；1：预览。2：下载。
        businessType: this.$utils.map.getUserBstpNo(),
      };
      downloadReport(params).then((res) => {
        this.$utils.openBlob(res.data);
      });
    },
    // 提交ASG-LTC报告确认
    submitAsgLtcConfirmReport() {
      this.$messageBox
        .confirm(
          "此操作表示您对报告结果无异议，并提交确认单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          const params = {
            orderNo: Number(this.myData.vbillcode),
            businessType: this.$utils.map.getUserBstpNo(),
          };
          submitReportConfirmation(params).then((res) => {
            this.openAsgLtc = false;
            this.$message({
              type: "success",
              message: "确认报告提交成功",
            });
            this.$emit("handleGetOrderLsit");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.mt30 {
  margin-top: 30px;
}
.operation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 86px;
  .item {
    display: flex;
    align-items: center;
    margin-right: 15px;
    height: 28px;
    .title,
    .iconfont {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      color: #c0c4cc;
    }

    .iconfont {
      width: 16px;
      height: 18px;
    }

    .active {
      color: #303133;
      cursor: pointer;
    }

    .active:hover {
      color: #1890ff;
    }
  }

  .more {
    cursor: pointer;
    color: $theme_color;
    font-size: 14px;
  }
}
</style>
