var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"mr black lh"},[_vm._v(_vm._s(_vm.myData.productname))])]),(_vm.userBstpSelected.includes('/bsg/otd/order'))?[_c('div',{staticClass:"row"},[_c('span',{staticClass:"gray"},[_vm._v(_vm._s(_vm.myData.samplename))]),(_vm.getProtocalName(_vm.myData))?_c('el-divider',{attrs:{"direction":"vertical"}}):_vm._e(),_c('span',{staticClass:"gray cs mr hr",on:{"click":function($event){return _vm.entrustProtocol(_vm.myData)}}},[_vm._v(_vm._s(_vm.getProtocalName(_vm.myData)))]),(
          _vm.myData.sfjj === 'Y' &&
          [12, 13, 14, 15, 16, 17, 25, 27, 28, 29].includes(_vm.myData.status)
        )?_c('svg-icon',{staticClass:"urgent mr",attrs:{"icon-class":"urgentSuccess"}}):_vm._e(),(
          _vm.myData.sfjj === 'Y' &&
          [11, 20, 21, 23, 24, 26].includes(_vm.myData.status)
        )?_c('svg-icon',{staticClass:"urgent mr",attrs:{"icon-class":"urgentReady"}}):_vm._e()],1)]:_vm._e(),(_vm.userBstpSelected.includes('/bsg/ltc/order'))?[_c('div',{staticClass:"row"},[_c('span',{staticClass:"gray cs hr",on:{"click":function($event){return _vm.$emit('handleDetail', _vm.myData)}}},[_vm._v("样本信息")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',{staticClass:"gray"},[_vm._v(_vm._s(_vm.getNewTitle(`合同号${_vm.myData.pk_busicontract}`)))]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',{staticClass:"gray"},[_vm._v(" "+_vm._s(_vm.getNewTitle(`项目号${_vm.myData.cprojectid}`))+" ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }