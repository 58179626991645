<!-- 下载文件  -->
<template>
  <el-drawer
    title="下载文件"
    :visible.sync="myOpen"
    size="75%"
    class="elDrawer"
    direction="rtl"
    v-if="myData"
    @close="$emit('input', false)"
  >
    <div class="content_wrap">
      <el-table :data="tableData" border class="elTable">
        <el-table-column prop="date" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="name" label="类型" width="180">
        </el-table-column>
        <el-table-column prop="address" label="时间"> </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="elPagination">
      <el-pagination
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
    <slot name="footer">
      <div class="footer">
        <el-button @click="$emit('input', false)">取 消</el-button>
        <el-button type="primary">确 认</el-button>
      </div>
    </slot>
  </el-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      myData: this.data,
      myOpen: this.value,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ], //表格数据
      pageSizes: [12, 24, 36], //分页数量
      pageSize: 12, //分页数量
      total: 100, //数据总计
      pageNum: 1, //当前页
    };
  },
  watch: {
    value(newVal) {
      this.myOpen = newVal;
    },
    data(newVal) {
      this.myData = newVal;
    },
  },
  methods: {
    // 改变页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.handleGetOrderLsit();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleGetOrderLsit();
    },
    // 查找样本列表
    handleGetOrderLsit() {},
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elDrawer {
  .content_wrap {
    padding: 10px 22px;
    text-align: left;
    box-sizing: border-box;
    min-height: calc(100% - 102px);
    ::v-deep .elTable {
      width: 80%;
    }
  }
  .elPagination {
    @include end;
    padding: 25px;
    background: #ffffff;
    margin-top: auto;
  }
  ::v-deep .el-drawer__header > :first-child {
    text-align: left;
  }
  .footer {
    display: flex;
    width: 100%;
    padding: 10px 22px 30px 22px;
    border-top: 1px solid $theme_bd_color;
  }
}
</style>
