<template>
  <div class="order_wrap elTab">
    <div class="order">
      <el-row class="tab_header">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane :name="route.draft">
              <span slot="label">草稿箱&nbsp;&nbsp;{{ draftTotal }}</span>
            </el-tab-pane>
            <el-tab-pane label="订单列表" :name="route.order"></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="operation">
        <div class="btn">
          <el-button
            type="primary"
            @click="openCreateOrder"
            size="medium"
            v-if="showCreateOrderBtn"
            >新建订单</el-button
          >
          <!-- <el-button plain @click="openBatchCreateOrder" size="medium"
            >批量新建</el-button
          > -->
          <!-- <el-button  plain @click.native="copy" size="medium">复制新建</el-button> -->
          <!-- <el-button type="primary" plain>批量确认报告</el-button>
					<el-button type="primary" plain>批量下载报告</el-button>
					<el-button type="primary" plain>导出</el-button> -->
        </div>
        <query-form :data="form" :tableData="tableData" @search="search" />
      </div>

      <!-- 列表 -->
      <asg-table
        :data="tableData"
        :upload="upload"
        @handleGetOrderLsit="refreshOrder"
        ref="orderTable"
        v-bind="$attrs"
      />
    </div>

    <!-- 分页 -->
    <div class="elPagination">
      <el-pagination
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改默认密码 -->
    <modify-default-password :open="open" />

    <!-- 下单 -->
    <apt-create-order
      v-model="showCreateOrder"
      @success="submitOrderSuccess"
      title="新建订单"
    />

    <!-- 批量下单 -->
    <apt-batch-create-order v-model="showBatchCreateOrder" />
  </div>
</template>

<script>
import asgTable from "@/views/asg/order/asgTable.vue";
import queryForm from "@/views/components/order/order/queryForm.vue";
import { getBsgOtdOrderList } from "@/api/order/index";
import modifyDefaultPassword from "@/views/home/home/modifyDefaultPassword.vue";
import { mixinUser } from "@/mixins/index";
import {
  mixinOrder
} from "@/views/components/order/order/order/index.js";
export default {
  mixins: [mixinUser, mixinOrder],
  components: {
    asgTable,
    queryForm,
    modifyDefaultPassword,
  },
  data() {
    return {
      active: "/asg/order", //当前激活的tab路由选项
      route: {
        order: "/asg/order",
        draft: "/asg/draft",
      }, //tab路由列表
    };
  },
  methods: {
    //获取草稿订单列表
    async handleGetOrderLsit() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        [this.form.selectValue]: this.form.searchValue, //订单编号
        // startTime: this.$utils.map.parseTime(this.form.date[0], "{y}-{m}-{d}"),
        // endTime: this.$utils.map.parseTime(this.form.date[1], "{y}-{m}-{d}"),
        status: this.form.state == -1 ? "" : this.form.state,
        type: this.$utils.storage.get("user").userBstp,
      };
      const params2 = this.$utils.map.getNotEmptyObj(params);
      const orderData = await getBsgOtdOrderList(params2);
      //获取table数据
      this.tableData = orderData.orderList;
      this.total = orderData.totalNum;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/components/order/order/order/index.scss";
</style>
