<!-- 余样回收  -->
<template>
  <el-drawer
    title="余样回收"
    :visible.sync="myOpen"
    size="75%"
    class="elDrawer"
    direction="rtl"
    v-if="myData"
    @close="$emit('input', false)"
  >
    <div class="content_wrap">
      <el-form
        :model="myData"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo_form"
      >
        <el-col :span="24">
          <p class="retrieve_title">
            请确认是否要对本次项目服务所剩余的样本做回收处理，请选择：
          </p>
        </el-col>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="retrieve" label-width="0">
              <el-radio-group v-model="form.retrieve" class="retrieve_wrap">
                <el-radio label="N">
                  <span class="retrieve_btn">
                    <span class="common">不要回收</span>
                    <span class="common"
                      >（说明：按照合同约定保存三个月后做销毁处理）</span
                    >
                  </span>
                </el-radio>
                <el-radio label="Y" class="retrieve">
                  <span class="retrieve_btn">
                    <span class="common">需要回收</span>
                    <span class="common">（说明：请填写回寄收获地址信息）</span>
                  </span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.retrieve === 'Y'">
          <el-col :span="12">
            <el-form-item prop="addressee" label="收件人：">
              <el-input
                v-model="myData.addressee"
                placeholder="请填写"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="phone" label="手机号：">
              <el-input v-model="myData.phone" placeholder="请填写"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.retrieve === 'Y'">
          <el-col :span="24">
            <el-form-item prop="address" label="详细地址：">
              <el-input
                v-model="myData.address"
                placeholder="请填写"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p class="demo_info">余样信息：</p>
      <el-table :data="tableData" border class="elTable">
        <el-table-column prop="date" label="样本编号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="样本名称" width="180">
        </el-table-column>
        <el-table-column prop="address" label="样本状态"> </el-table-column>
        <el-table-column prop="address" label="管数"> </el-table-column>
        <el-table-column prop="address" label="样本类型"> </el-table-column>
      </el-table>
    </div>
    <div class="elPagination">
      <el-pagination
        v-if="tableData.length > 0"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
    <slot name="footer">
      <div class="footer">
        <el-button @click="$emit('input', false)">取 消</el-button>
        <el-button type="primary">确 认</el-button>
      </div>
    </slot>
  </el-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      myData: this.data,
      myOpen: this.value,
      rules: {
        retrieve: [
          { required: true, message: "请选择是否需要回收", trigger: "blur" },
        ],
        addressee: [
          { required: true, message: "请填写收件人", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
      form: {
        retrieve: "N", //是否需要回收
      }, //表单数据
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ], //表格数据
      pageSizes: [12, 24, 36], //分页数量
      pageSize: 12, //分页数量
      total: 100, //数据总计
      pageNum: 1, //当前页
    };
  },
  watch: {
    value(newVal) {
      this.myOpen = newVal;
    },
    data(newVal) {
      this.myData = newVal;
    },
  },
  methods: {
    // 改变页数
    handleCurrentChange(val) {
      this.pageNum = val;
      this.handleGetOrderLsit();
    },
    // 改变每页数量
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleGetOrderLsit();
    },
    // 查找样本列表
    handleGetOrderLsit(){}
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.elDrawer {
  .content_wrap {
    padding: 10px 22px;
    text-align: left;
    box-sizing: border-box;
    min-height: calc(100% - 102px);
    .demo_form {
      .retrieve_title {
        font-size: 16px;
      }
    }
    .retrieve_wrap ::v-deep .retrieve {
      .retrieve_btn {
        .red {
          color: red;
        }
      }
    }
    .demo_info {
      font-size: 16px;
      padding: 20px 0 15px 0;
    }
    ::v-deep .elTable {
      width: 80%;
    }
  }
  .elPagination {
    @include end;
    padding: 25px;
    background: #ffffff;
    margin-top: auto;
  }
  ::v-deep .el-drawer__header > :first-child {
    text-align: left;
  }
  .footer {
    display: flex;
    width: 100%;
    padding: 10px 22px 30px 22px;
    border-top: 1px solid $theme_bd_color;
  }
}
</style>
