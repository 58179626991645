<template>
  <div class="operation">
    <div class="row">
      <span class="item" @click="viewDetail(myData)">
        <span class="title">订单详情</span></span
      >
      <span class="item" @click="retrieveDemo(myData)">
        <span class="title">余样回收</span></span
      >
    </div>
    <!-- 查看BSG-LTC业务类型的订单详情 -->
    <bsg-ltc-detail
      v-model="openBsgLtc"
      v-if="openBsgLtc"
      :data="myData"
      ref="bsgLtc"
    />
    <!-- 余样回收 -->
    <retrieve-demo v-model="openRetrieveDemo" :data="myData" />
  </div>
</template>

<script>
import bsgLtcDetail from "@/views/components/order/order/bsgLtcDetail.vue";
import { order } from "@/utils/dict";
import retrieveDemo from "./retrieveDemo.vue";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    bsgLtcDetail,
    retrieveDemo,
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
    selected: {
      handler(newVal) {
        this.mySelected = newVal;
      },
      deep: true,
    },
  },
  data() {
    return {
      myData: this.data,
      detailData: null, //查看详情弹窗参数
      mySelected: this.selected, //当前选中项
      openBsgLtc: false, //查看bsgLtc详情弹窗
      openRetrieveDemo: false, //打开余样回收弹窗
    };
  },
  methods: {
    // 查看订单详情
    async viewDetail(val) {
      const { busitype } = val;
      //需要判断不同业务类型，调不同接口，以便查询订单详情
      if (busitype === order.busiType.asgLtc) {
        this.openBsgLtc = true;
      }
    },
    // 余样回收
    retrieveDemo() {
      this.openRetrieveDemo = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";
.operation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  .row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    line-height: 28px;
    .item {
      display: flex;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
      .title,
      .iconfont {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        color: $theme_color;
      }
      .iconfont {
        width: 16px;
        height: 18px;
      }
    }
  }
  .more {
    cursor: pointer;
    color: $theme_color;
    font-size: 14px;
  }
  .dialogViewReport {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
    border: $theme_color;
  }
  .dialogConfirmReport {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
    border: $theme_color;
    flex-wrap: wrap;
    .elSteps {
      width: 70%;
    }
    .agree_wrap {
      @include center;
      width: 100%;
      .agree {
      }
    }
    .nextStep {
      border: 1px solid $theme_color;
      width: 200px;
      height: 50px;
      @include center;
      cursor: pointer;
    }
    .start {
      justify-content: start;
      width: 100%;
    }
    .pl {
      padding-left: 28px;
    }
  }
}

/*样式穿透*/
.applyAfterSaleForm {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & ::v-deep .remarkTextarea {
    .el-textarea__inner {
      width: 450px;
      height: 130px;
    }
  }
}
.applyAfterSaleForm ::v-deep .add {
  font-size: 28px;
  color: #8c939d;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
}

.applyAfterSaleForm ::v-deep .TheortSeqImg {
  .el-upload-list--picture-card,
  .el-upload-list__item,
  .el-upload--picture-card {
    width: 94px;
    height: 94px;
  }
  .el-upload--picture-card {
    line-height: 94px;
  }
}
</style>
