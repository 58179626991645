<template>
  <div class="orderTable">
    <el-table
      :data="myData"
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#fafafa',
        color: '#000000',
        'font-weight': 400,
      }"
      class="elTable"
      ref="elTable"
      @selection-change="selectChange"
    >
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column label="订单信息" align="left" min-width="18%">
        <div slot-scope="scope" class="column">
          <div class="row">
            <span class="mr black">{{ scope.row.vbillcode }}</span>
            <el-tooltip
              class="item"
              effect="light"
              content="点击复制订单号"
              placement="right"
            >
              <i
                class="el-icon-document-copy cs copy black"
                :data-clipboard-text="scope.row.vbillcode"
              ></i>
            </el-tooltip>
          </div>
          <div class="row">
            <el-tooltip
              class="item"
              effect="light"
              content="订单创建时间"
              placement="right"
            >
              <span class="mr gray">{{
                $utils.map.parseTime(scope.row.dbilldate)
              }}</span>
              <!-- <i class="el-icon-stopwatch gray"></i> -->
            </el-tooltip>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="检测信息" align="left" min-width="32%">
        <div slot-scope="scope" class="column">
          <test-info :data="scope.row" @handleDetail="handleDetail" />
        </div>
      </el-table-column>
      <el-table-column label="检测结果" min-width="10%">
        <template slot-scope="scope">
          <result :data="scope.row" v-on="$listeners" />
        </template>
      </el-table-column>
      <el-table-column label="状态描述" min-width="20%">
        <template slot-scope="scope">
          <span class="status" :style="getStatusColor(scope.row.status)">{{
            getStatusName(scope.row.status)
          }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="operationRemark" label="操作备注">
        <template slot-scope="scope">
          <operation-remark :data="scope.row" />
        </template>
      </el-table-column> -->
      <el-table-column
        prop="operation"
        label="操作"
        align="right"
        min-width="20%"
      >
        <template slot-scope="scope">
          <operation
            :data="scope.row"
            :selected="selected"
            v-bind="$attrs"
            v-on="$listeners"
            ref="operation"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import operation from "./operation.vue";
import result from "./result.vue";
import { order } from "@/utils/dict";
import testInfo from "@/views/components/order/order/testInfo.vue";
export default {
  components: {
    operation,
    result,
    testInfo,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      selected: [], //当前选中项
      resultType: undefined, //展示的result组件类型
      user: this.$utils.storage.get("user"), //用户数据
    };
  },
  methods: {
    // 获取result组件类型
    getResultType() {
      const asgLtc = [];
    },
    // 加载详情
    handleDetail(row) {
      this.$refs.operation.viewDetail(row);
    },
    selectChange(val) {
      this.selected = val;
    },
    // 获取状态name
    getStatusName(value) {
      const { status } = order;
      let name;
      status.forEach((item) => {
        if (item.value === value) {
          name = item.name;
          return;
        }
      });
      return name;
    },
    // 获取订单状态颜色
    getStatusColor(val) {
      const selected = order.status.find((item) => item.value === val);
      if (selected && selected.color) {
        return `color: ${selected.color}; border: 1px solid ${selected.bdColor}; background: ${selected.bgColor};`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/elementuiReset.scss";

.orderTable {
  .elTable {
    .demoInfo {
      color: $theme_color;
      cursor: pointer;
      align-items: center;
    }
  }
}

/*样式穿透*/
.elTable ::v-deep .cell {
  overflow: visible;
}

.elTable ::v-deep tbody {
  .column {
    .row {
      display: flex;
      align-items: center;
      height: 28px;

      .lh {
        line-height: 1.1;
      }

      .mr {
        margin-right: 10px;
      }

      .black {
        color: #303133;
      }

      .gray {
        color: #909399;
      }

      .cs {
        cursor: pointer;
      }

      .hr:hover {
        color: #1890ff;
      }

      .pd {
        padding: 0 10px;
      }

      .urgent {
        width: 36px;
        height: 36px;
      }
    }
  }

  .status {
    padding: 8px 16px;
    // width: 80px;
    // height: 32px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 20px;
    // @include center;
  }
}
</style>
