<template>
  <div class="column">
    <div class="row">
      <span class="mr black lh">{{ myData.productname }}</span>
    </div>
    <!-- BSG-OTD -->
    <template v-if="userBstpSelected.includes('/bsg/otd/order')">
      <div class="row">
        <span class="gray">{{ myData.samplename }}</span>
        <el-divider
          v-if="getProtocalName(myData)"
          direction="vertical"
        ></el-divider>
        <span class="gray cs mr hr" @click="entrustProtocol(myData)">{{
          getProtocalName(myData)
        }}</span>
        <svg-icon
          v-if="
            myData.sfjj === 'Y' &&
            [12, 13, 14, 15, 16, 17, 25, 27, 28, 29].includes(myData.status)
          "
          icon-class="urgentSuccess"
          class="urgent mr"
        />
        <svg-icon
          v-if="
            myData.sfjj === 'Y' &&
            [11, 20, 21, 23, 24, 26].includes(myData.status)
          "
          icon-class="urgentReady"
          class="urgent mr"
        />
      </div>
    </template>
    <!-- BSG-LTC -->
    <template v-if="userBstpSelected.includes('/bsg/ltc/order')">
      <div class="row">
        <span class="gray cs hr" @click="$emit('handleDetail', myData)"
          >样本信息</span
        >
        <el-divider direction="vertical"></el-divider>
        <span class="gray">{{
          getNewTitle(`合同号${myData.pk_busicontract}`)
        }}</span>
        <el-divider direction="vertical"></el-divider>
        <span class="gray">
          {{ getNewTitle(`项目号${myData.cprojectid}`) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { order } from "@/utils/dict";
import { mixinUser } from "@/mixins/index";
export default {
  mixins: [mixinUser],
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  watch: {
    data(newVal) {
      this.myData = newVal;
    },
  },
  data() {
    return {
      myData: this.data,
      busiType: order.busiType,
    };
  },
  components: {},
  methods: {
    // 获取新的字段名
    getNewTitle(title) {
      return title;
    },
    // 获取合同字段名
    getProtocalName(val) {
      const { status } = val;
      const protocolStatus = [12, 13, 14, 15, 16, 17, 24, 25, 26, 27, 28, 29]; //可以展示委托协议的状态
      if (protocolStatus.includes(status)) {
        return "委托协议";
      }
    },
    // 委托协议
    entrustProtocol(val) {
      const { contractUrl, busitype } = val;
      //只有BSG-OTD业务类型才显示委托协议，其它显示合同编号
      if (busitype !== order.busiType.bsgOtd) return;
      if (contractUrl) window.open(contractUrl);
    },
  },
  created() {
    this.getUserData();
  },
};
</script>
<style lang="scss" scoped></style>
